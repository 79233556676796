import { IonContent } from '@ionic/react';
import React from 'react';
import '../Page.css';
import Step1 from './Steps/Step1';
import Step7 from './Steps/Step7';
// import { PermitStore } from '../../store/PermitStore';
// import { UserStore } from '../../store/UserStore';

const ApplicationSteps = () => {
    // const application = PermitStore.useState((s) => s.application);
    // const userDoc = UserStore.useState((s) => s.userDoc);

    // useEffect(() => {
    //     console.log('userDoc => ', userDoc);
    // }, [userDoc]);
    // useEffect(() => {
    //     console.log('application data => ', application);
    // }, [application]);
    return (
        <IonContent>
            {/****** First Name Area ******/}
            <Step1 />
            {/****** Success ******/}
            <Step7 />
        </IonContent>
    );
};

export default ApplicationSteps;
