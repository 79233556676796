import {
    IonContent,
    IonPage,
    IonImg,
    IonButton,
    IonItem,
    IonList,
    IonLabel,
    IonInput,
    IonRow,
    IonCol,
    IonText,
    IonGrid,
    IonIcon,
    IonLoading,
} from '@ionic/react';
import React, { useState } from 'react';
import { auth, signInWithGoogle, signInWithFB } from '../firebase';
/* Pullstate Store */

import PLOfull from '../assets/images/PLO_full.png';
import { logoFacebook, logoGoogle } from 'ionicons/icons';
import { TownStore } from '../store/TownStore';

const Login = () => {
    const [email, setEmail] = useState(``);
    const [password, setPassword] = useState(``);
    const [error, setError] = useState(``);
    const [showLoading, setShowLoading] = useState(false);
    const [emailPassword, setEmailPassword] = useState(false);
    const townDoc = TownStore.useState((s) => s.townDoc);

    const signInWithEmailAndPasswordHandler = (event) => {
        setShowLoading(true);
        event.preventDefault();
        setError(``);

        if (email.length < 4) {
            setError(`Name must be at least 4 characters.`);
        }

        if (password.length < 4) {
            setError(`Password must be at least 4 characters.`);
        }

        if (error === ``) {
            auth.signInWithEmailAndPassword(email, password)
                .then(() => {
                    console.log('logged in');
                    // history.push('/', {direction: 'none'});
                })
                .catch((e) => {
                    setError(e.message);
                });
        }
        setShowLoading(false);
    };

    if (townDoc) {
        return (
            <IonPage mode='ios'>
                <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} />
                <IonContent>
                    <IonGrid className='contentArea ion-padding-horizontal'>
                        <IonRow>
                            <IonCol>
                                <div style={{ height: 50 }} />
                                <IonImg
                                    className='ion-padding'
                                    style={{ height: 100 }}
                                    src={PLOfull}
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonImg className='ion-padding' src={townDoc.logo} />
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <h2 className='ion-text-center headingText'>
                                    One-Click Registration/Login
                                </h2>
                            </IonCol>
                        </IonRow>
                        {!emailPassword ? (
                            <>
                                <IonRow>
                                    <IonCol>
                                        <IonButton
                                            // className="ion-margin-top"
                                            expand='block'
                                            shape='round'
                                            color='primary'
                                            type='submit'
                                            onClick={signInWithGoogle}
                                        >
                                            <IonIcon
                                                icon={logoGoogle}
                                                className='ion-padding-end'
                                            />{' '}
                                            Log in With Google
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                                {/* <IonRow>
            <IonCol>
              <IonButton
                // className="ion-margin-top" 
                expand="block"
                shape="round"
                color="primary"
                type="submit" 
                onClick={signInWithMS}
              ><IonIcon icon={logoMicrosoft} className="ion-padding-end"/> Log in With Microsoft</IonButton>
            </IonCol>
          </IonRow> */}
                                <IonRow>
                                    <IonCol>
                                        <IonButton
                                            // className="ion-margin-top"
                                            expand='block'
                                            shape='round'
                                            color='primary'
                                            type='submit'
                                            onClick={signInWithFB}
                                        >
                                            <IonIcon
                                                icon={logoFacebook}
                                                className='ion-padding-end'
                                            />{' '}
                                            Log in With Facebook
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <h2 className='ion-text-center headingText'>Or</h2>
                                        <IonButton
                                            // className="ion-margin-top"
                                            expand='block'
                                            shape='round'
                                            color='primary'
                                            fill='outline'
                                            type='submit'
                                            onClick={() => setEmailPassword(!emailPassword)}
                                        >
                                            Use Email & Password Instead
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </>
                        ) : (
                            <>
                                <IonRow>
                                    <IonCol>
                                        <p className='ion-text-center'>
                                            <IonButton
                                                fill='clear'
                                                onClick={() => setEmailPassword(!emailPassword)}
                                            >
                                                Use One-Click Log in instead
                                            </IonButton>
                                        </p>
                                        <form
                                            onSubmit={(e) => signInWithEmailAndPasswordHandler(e)}
                                        >
                                            <IonList>
                                                <IonItem lines='full'>
                                                    <IonLabel position='floating'>Email</IonLabel>
                                                    <IonInput
                                                        value={email}
                                                        type='email'
                                                        inputMode='email'
                                                        // pattern="email"
                                                        onIonChange={(e) =>
                                                            setEmail(e.detail.value)
                                                        }
                                                    ></IonInput>
                                                </IonItem>
                                                <IonItem lines='full'>
                                                    <IonLabel position='floating'>
                                                        Password
                                                    </IonLabel>
                                                    <IonInput
                                                        value={password}
                                                        type='password'
                                                        onIonChange={(e) =>
                                                            setPassword(e.detail.value)
                                                        }
                                                    ></IonInput>
                                                </IonItem>
                                            </IonList>
                                            <div className='ion-margin-top ion-padding-vertical'>
                                                <IonButton
                                                    // className="ion-margin-top"
                                                    expand='block'
                                                    shape='round'
                                                    color='primary'
                                                    type='submit'
                                                    onClick={(event) => {
                                                        signInWithEmailAndPasswordHandler(event);
                                                    }}
                                                >
                                                    Log in
                                                </IonButton>
                                            </div>
                                        </form>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonButton
                                            routerLink='/register'
                                            color='primary'
                                            fill='clear'
                                            expand='block'
                                        >
                                            Register New Account
                                        </IonButton>
                                    </IonCol>
                                    <IonCol>
                                        <IonButton
                                            routerLink='/forgot'
                                            color='primary'
                                            fill='clear'
                                            expand='block'
                                        >
                                            Forgot Password
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </>
                        )}
                        <IonRow>
                            <IonCol>
                                {error !== '' ? (
                                    <IonText color='danger'>
                                        <p className='ion-padding-start'>{error}</p>
                                    </IonText>
                                ) : (
                                    <IonRow>
                                        <IonCol>
                                            <p className='ion-text-center'>
                                                <small>
                                                    If you have previously created an account with
                                                    email and password, or would prefer not to use a
                                                    one-click log in, please choose this option.
                                                </small>
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                )}
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            </IonPage>
        );
    } else {
        return (
            <IonPage>
                <IonContent>
                    <IonLoading />
                </IonContent>
            </IonPage>
        );
    }
};

export default Login;
