import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonLoading } from '@ionic/react';
import React, { useState, useEffect } from 'react';
// import logo from '../assets/images/logo.jpg';
import firebaseApp, { auth } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useLocation } from 'react-router';
import { UserStore } from '../store/UserStore';
import dayjs from 'dayjs';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const Success = (props) => {
    const [user] = useAuthState(auth);
    const userDoc = UserStore.useState((s) => s.userDoc);
    const [loading, setLoading] = useState(true);
    const query = useQuery();
    const id = query.get('id');
    const userRef = firebaseApp.firestore().collection('users').doc(user.uid);
    const licenseRef = firebaseApp.firestore().collection('licenses');

    useEffect(() => {
        if (id && userDoc) {
            userRef
                .collection('pets')
                .doc(id)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        const data = doc.data();
                        const petId = doc.id;
                        licenseRef.add({
                            pet: { petId, ...data },
                            userDoc,
                            licenseExpiry: dayjs(Date.now()).add(1, 'year').format(),
                        });
                    }
                })
                .then(() => {
                    userRef
                        .collection('pets')
                        .doc(id)
                        .update({
                            licensed: true,
                            licenseExpiry: dayjs(Date.now()).add(1, 'year').format(),
                        })
                        .then(() => {
                            setLoading(false);
                            window.location.replace('/pets');
                        });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, userDoc]);

    return (
        <IonPage>
            <IonContent>
                <IonGrid className='contentArea'>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonLoading isOpen={loading} />
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default Success;
