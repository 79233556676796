import {
    IonContent,
    IonCard,
    IonPage,
    IonGrid,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonLoading,
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
// import logo from '../assets/images/logo.jpg';
import firebaseApp, { auth } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

const Permit = (props) => {
    const [user] = useAuthState(auth);
    const [permit, setPermit] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const fbDb = firebaseApp.firestore().collection('users').doc(user.uid).collection('permits');

    useEffect(() => {
        const getPermit = () => {
            if (user !== null) {
                fbDb.doc(props.match.params.id)
                    .get()
                    .then((doc) => {
                        if (doc.exists) {
                            const data = doc.data();
                            const id = doc.id;
                            setPermit({ id, ...data });
                            // console.log("Document data:", doc.data());
                        } else {
                            // doc.data() will be undefined in this case
                            setPermit(null);
                            console.log('No such document!');
                        }
                    })
                    .catch(function (error) {
                        console.log('Error getting document:', error);
                    });
            }
        };
        getPermit();
    }, [fbDb, props.match.params.id, user]);
    useEffect(() => {
        // console.log(permit)
    }, [permit]);
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    });
    if (!permit) {
        return (
            <IonPage>
                <IonContent>
                    <IonGrid className='contentArea'>
                        <IonCard mode='ios'>
                            <IonCardHeader className='ion-margin-top'>
                                <IonCardSubtitle>Burn Permit</IonCardSubtitle>
                                <IonCardTitle>Not Found</IonCardTitle>
                            </IonCardHeader>
                        </IonCard>
                    </IonGrid>
                </IonContent>
            </IonPage>
        );
    }
    if (permit && !isLoading) {
        return (
            <IonPage>
                <IonContent>
                    <IonCard mode='ios'>
                        <IonCardHeader className='ion-margin-top'>
                            <IonCardSubtitle>Burn Permit</IonCardSubtitle>
                            <IonCardTitle>Application</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent></IonCardContent>
                    </IonCard>
                </IonContent>
            </IonPage>
        );
    } else if (isLoading) {
        return <IonLoading />;
    }
};

export default Permit;
