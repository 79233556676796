import Menu from './components/Menu.jsx';
import React, { useEffect } from 'react';
import {
    IonApp,
    IonContent,
    IonLoading,
    IonPage,
    IonRouterOutlet,
    IonSplitPane,
} from '@ionic/react';

import { Redirect, Route } from 'react-router-dom';

/*Firebase Stuff */
import firebaseApp from './firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Pullstate Stores */
import { PermitStore } from './store/PermitStore';
import { UserStore } from './store/UserStore';
import { AppStore } from './store/AppStore';

import Home from './pages/Home';
import Pets from './pages/Pets';
import Account from './pages/Account';
import Login from './pages/Login.jsx';
import Forgot from './pages/Forgot.jsx';
import Register from './pages/Register.jsx';
import Profile from './pages/Profile.jsx';
import NoNetwork from './components/NoNetwork';
// import { useStatus } from '@capacitor-community/react-hooks/network';
// import { Plugins } from '@capacitor/core';
// import { toast } from './components/Toast/Toast.jsx';
import Licenses from './pages/Licenses';
import Permit from './pages/Permit.jsx';
import { PetStore } from './store/PetStore.js';
import { TownStore } from './store/TownStore';
import { useLocation } from 'react-router';
import Success from './pages/Success.jsx';
import Payment from './pages/Payment.jsx';

// const { Network } = Plugins;

const auth = firebaseApp.auth();

export const logout = () => {
    firebaseApp.auth().signOut();
};

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const App = () => {
    const [user, loading, error] = useAuthState(auth);
    const network = AppStore.useState((s) => s.network);
    const firestore = firebaseApp.firestore();
    const query = useQuery();
    const id = 'Brooks';

    useEffect(() => {
        if (id) {
            const getTownDoc = () => {
                // console.log(user)
                firestore
                    .collection('municipality')
                    .doc(id)
                    .get()
                    .then((doc) => {
                        if (doc.exists) {
                            const data = doc.data();
                            const id = doc.id;
                            const terms = data.terms;
                            const agree = data.agree;
                            // console.log('data ', data);
                            TownStore.update((s) => {
                                s.townDoc = { id, ...data };
                            });
                            PermitStore.update((s) => {
                                s.terms = terms;
                                s.agree = agree;
                            });
                        } else {
                            TownStore.update((s) => {
                                s.townDoc = null;
                            });
                        }
                    })
                    .catch((error) => console.log(error));
            };

            const getAnnouncements = () => {
                firestore
                    .collection('municipality')
                    .doc(id)
                    .collection('announcements')
                    .limit(3)
                    .get()
                    .then((querySnapshot) => {
                        const announcementsList = [];
                        querySnapshot.forEach((doc) => {
                            const id = doc.id;
                            const data = doc.data();
                            // doc.data() is never undefined for query doc snapshots
                            announcementsList.push({ id, ...data });
                            // console.log(doc.id, " => ", doc.data());
                        });
                        const annoucementsSorted = announcementsList.sort((a, b) =>
                            a.dateCreated > b.dateCreated
                                ? -1
                                : b.dateCreated > a.dateCreated
                                ? 1
                                : 0
                        );
                        TownStore.update((s) => {
                            s.announcements = annoucementsSorted;
                        });
                    });
            };
            getTownDoc();
            getAnnouncements();
        }
    }, [id, query, firestore]);

    useEffect(() => {
        // console.log(user);
        if (user) {
            const getUserDoc = () => {
                // console.log(user)
                firestore
                    .collection('users')
                    .doc(user.uid)
                    .get()
                    .then((doc) => {
                        if (doc.exists) {
                            const data = doc.data();
                            const uid = doc.id;
                            UserStore.update((s) => {
                                s.userDoc = { uid, ...data };
                            });
                        }
                        if (!doc.exists) {
                            firestore
                                .collection('users')
                                .doc(user.uid)
                                .set({
                                    firstName: null,
                                    lastName: null,
                                    address: null,
                                    fullAddress: null,
                                    phone: null,
                                    email: user.email,
                                    postal: null,
                                })
                                .then(() => {
                                    getUserDoc();
                                });
                        }
                    })
                    .catch((error) => console.log(error));
            };

            getUserDoc();

            UserStore.update((s) => {
                s.user = user;
            });

            const getUserPermits = () => {
                // console.log(user)
                firestore
                    .collection('permits')
                    .where('user', '==', user.uid)
                    .get()
                    .then((querySnapshot) => {
                        const permitsList = [];
                        querySnapshot.forEach((doc) => {
                            const data = doc.data();
                            const id = doc.id;
                            permitsList.push({ id, ...data });
                            // doc.data() is never undefined for query doc snapshots
                            // console.log(doc.id, " => ", doc.data());
                        });
                        const orderedPermitsList = permitsList.sort(function compare(a, b) {
                            if (b.startDate < a.startDate) {
                                return -1;
                            }
                            if (b.startDate > a.startDate) {
                                return 1;
                            }
                            return 0;
                        });
                        PermitStore.update((s) => {
                            s.permits = orderedPermitsList;
                        });
                    })
                    .catch((error) => console.log(error));
            };
            const getUserPets = () => {
                // console.log(user)
                firestore
                    .collection('users')
                    .doc(user.uid)
                    .collection('pets')
                    .get()
                    .then((querySnapshot) => {
                        const petsList = [];
                        querySnapshot.forEach((doc) => {
                            const data = doc.data();
                            const id = doc.id;
                            petsList.push({ id, ...data });
                            // doc.data() is never undefined for query doc snapshots
                            // console.log(doc.id, ' => ', doc.data());
                        });
                        // console.log(petsList);
                        PetStore.update((s) => {
                            s.pets = petsList;
                        });
                    })
                    .catch((error) => console.log(error));
            };
            getUserPermits();
            getUserPets();
        }
    }, [user, firestore]);

    if (network === 'disconnected') {
        return <NoNetwork />;
    }

    if (loading) {
        // console.log('llloooadddding', user)
        return (
            <IonApp>
                <IonPage>
                    <IonContent>
                        <IonLoading />
                    </IonContent>
                </IonPage>
            </IonApp>
        );
    }

    if (error) {
        return (
            <IonApp>
                <div>
                    <p>Error: {error}</p>
                </div>
            </IonApp>
        );
    }
    if (user) {
        return (
            <IonApp>
                <IonSplitPane contentId='main'>
                    <Menu />
                    <IonRouterOutlet id='main'>
                        <Redirect from='/login' to='/account' exact />
                        <Redirect from='/register' to='/account' exact />
                        <Redirect from='/home' to='/account' exact />
                        <Redirect from='/' to='/home' exact />
                        <Route path={'/home'} component={Account} />
                        <Route path={'/licenses'} component={Licenses} />
                        <Route path={'/pets'} component={Pets} exact />
                        <Route path='/profile' component={Profile} exact />
                        <Route path='/payment' component={Payment} exact />
                        <Route path='/license/:id' component={Permit} exact />
                        <Route path='/success/' component={Success} exact />
                    </IonRouterOutlet>
                </IonSplitPane>
            </IonApp>
        );
    }
    if (!user && !loading) {
        return (
            <IonApp>
                {/* <Menu /> */}
                <IonRouterOutlet id='main'>
                    <Route path='/' component={Login} exact />
                    <Route path='/home' component={Home} exact />
                    <Route path='/forgot' component={Forgot} exact />
                    <Route path='/register' component={Register} exact />
                    <Redirect from='/login' to='/' exact />
                    <Redirect from='/account' to='/' exact />
                    <Redirect from='/pets' to='/' exact />
                    <Redirect from='/license' to='/' exact />
                    <Redirect from='/licenses' to='/' exact />
                    <Redirect from='/profile' to='/' exact />
                </IonRouterOutlet>
            </IonApp>
        );
    }
};

export default App;
