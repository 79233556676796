import { Store } from 'pullstate';

export const AppStore = new Store({
    network: null,
    onboardingModal: false,
    onboardingStep: 0,
    petOnboadingModal: false,
    petOnboardingStep: 0,
    municipality: null,
    pet: null,
    petModal: false,
});
