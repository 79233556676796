import React, { useState } from 'react';
import '../../Page.css';
/* Pullstate Store */
import { PermitStore } from '../../../store/PermitStore';
import {
    IonBadge,
    IonButton,
    IonCol,
    IonGrid,
    IonItem,
    IonLabel,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonSpinner,
} from '@ionic/react';
import { PetStore } from '../../../store/PetStore';
import firebaseApp, { firestore } from '../../../firebase';
import { TownStore } from '../../../store/TownStore';
import { toast } from '../../Toast/Toast';

const Step1 = () => {
    const step = PermitStore.useState((s) => s.applicationStep);
    const townDoc = TownStore.useState((s) => s.townDoc);
    const application = PermitStore.useState((s) => s.application);
    const permitTypes = PermitStore.useState((s) => s.permitTypes);
    const pets = PetStore.useState((s) => s.pets);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const unlicensedPets = pets.filter((p) => !p.licensed && !p.deceased);

    React.useEffect(() => {
        if (error) {
            toast(error);
        }
    }, [error]);
    const selectPet = (val) => {
        PermitStore.update((s) => {
            s.application = {
                ...application,
                pet: val,
            };
        });
    };
    const selectPermit = (val) => {
        PermitStore.update((s) => {
            s.application = {
                ...application,
                selectedPermit: val,
            };
        });
    };
    const handleClick = async () => {
        setLoading(true);
        var addMessage = firebaseApp
            .functions('northamerica-northeast1')
            .httpsCallable('createCheckoutSession');
        addMessage({
            url: window.location.origin,
            priceId: application.selectedPermit.priceId,
            pet: application.pet.id,
        })
            .then((result) => {
                setLoading(false);
                // Read result of the Cloud Function.
                // var sanitizedMessage = result.data.text;
                console.log(result);
                window.location.href = result.data;
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                setError('There was an error connecting to the payment gateway. Please try again.');
            });
    };

    React.useEffect(() => {
        if (application.pet) {
            const getPermitTypes = () => {
                // console.log(user)
                firestore
                    .collection('municipality')
                    .doc(townDoc.id)
                    .collection('permitTypes')
                    .get()
                    .then((querySnapshot) => {
                        const types = [];
                        querySnapshot.forEach((doc) => {
                            const id = doc.id;
                            const data = doc.data();
                            // console.log(doc.id, ' => ', doc.data());
                            types.push({ id, ...data });
                        });
                        PermitStore.update((s) => {
                            s.permitTypes = types;
                        });
                    })
                    .catch((error) => console.log(error));
            };

            getPermitTypes();
        }
    }, [application.pet, townDoc.id]);

    // React.useEffect(() => {
    //     console.log(application);
    // }, [application]);

    return (
        <div className={step === 1 ? null : 'ion-hide'}>
            {!loading ? (
                <>
                    <h2 className='ion-padding-horizontal onboardingTitles'>
                        Please complete the following form:
                    </h2>
                    <div style={{ height: 20 }} />

                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonItem>
                                    <IonLabel className='onboardingLabels' position='stacked'>
                                        Pet
                                    </IonLabel>
                                    <IonSelect
                                        value={application.pet}
                                        placeholder='Select One'
                                        onIonChange={(e) => selectPet(e.target.value)}
                                    >
                                        {pets &&
                                            unlicensedPets.map((pet, i) => (
                                                <IonSelectOption value={pet} key={i}>
                                                    {pet.name}
                                                </IonSelectOption>
                                            ))}
                                    </IonSelect>
                                </IonItem>
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol>
                                <IonItem>
                                    <IonLabel className='onboardingLabels' position='stacked'>
                                        License Type
                                    </IonLabel>
                                    <IonRadioGroup
                                        mode='md'
                                        aria-label={'List of burn permits'}
                                        checked={application.type}
                                        onIonChange={(e) => {
                                            selectPermit(e.target.value);
                                        }}
                                    >
                                        {permitTypes &&
                                            permitTypes.map((permit, i) => (
                                                <div key={i}>
                                                    <IonItem
                                                        aria-label={'Burn permit type'}
                                                        lines='none'
                                                    >
                                                        <IonLabel className='ion-text-wrap'>
                                                            {permit.id} {permit.desc ? '-' : ''}{' '}
                                                            {permit.desc}
                                                        </IonLabel>
                                                        <IonRadio slot='start' value={permit} />
                                                        <IonBadge slot='end'>
                                                            ${permit.price.toFixed(2)}
                                                        </IonBadge>
                                                    </IonItem>
                                                </div>
                                            ))}
                                    </IonRadioGroup>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonButton
                                    style={{ margin: '20px 10px' }}
                                    disabled={!application.selectedPermit || !application.pet}
                                    onClick={() => handleClick(application.selectedPermit)}
                                    expand='block'
                                >
                                    Purchase
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </>
            ) : (
                <>
                    <h2 className='ion-padding-horizontal onboardingTitles'>
                        Please wait as we process your request:
                    </h2>
                    <div style={{ height: 20 }} />
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <p style={{ textAlign: 'center' }}>
                                    <IonSpinner color='success' style={{ transform: 'scale(3)' }} />
                                </p>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </>
            )}
        </div>
    );
};

export default Step1;
