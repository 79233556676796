import React, { useState } from 'react';
import '../../Page.css';

// import { firestore } from '../../../firebase';
/* Pullstate Store */
import { PetStore } from '../../../store/PetStore';
import { IonButton, IonLoading } from '@ionic/react';
import { Checkmark } from 'react-checkmark';
// import { UserStore } from '../../../store/UserStore';
import { PermitStore } from '../../../store/PermitStore';

const Step6 = () => {
    const step = PetStore.useState((s) => s.petOnboardingStep);
    const petDoc = PetStore.useState((s) => s.petDoc);
    // const user = UserStore.useState((s) => s.user);
    const [isLoading, setIsLoading] = useState(false);

    const getLicense = () => {
        setIsLoading(true);
        PetStore.update((s) => {
            s.petOnboardingStep = 0;
        });
        PermitStore.update((s) => {
            s.applicationStep = 1;
        });
        setIsLoading(false);
    };
    const closePetModal = () => {
        setIsLoading(true);
        PetStore.update((s) => {
            s.petOnboardingStep = 0;
        });
        PetStore.update((s) => {
            s.petDoc = s.initialPetDoc;
        });
        setIsLoading(false);
    };
    return (
        <>
            <div className={step === 6 ? 'steps' : 'ion-hide'}>
                <>
                    <h2 className='ion-padding-horizontal onboardingTitles ion-text-center'>
                        {petDoc.name} has been saved to your pets!
                    </h2>
                    <div style={{ height: 40 }} />
                    <p className='ion-text-center'>
                        <Checkmark color='#2dd36f' size='xxLarge' />
                    </p>
                </>
                <div style={{ height: 40 }} />
                <IonButton
                    className='ion-margin'
                    shape='round'
                    expand='block'
                    color='success'
                    onClick={() => getLicense()}
                >
                    {`Get a license for ${petDoc.name}`}
                </IonButton>
                <IonButton
                    className='ion-margin'
                    shape='round'
                    expand='block'
                    fill='outline'
                    onClick={() => closePetModal()}
                >
                    {`Not now`}
                </IonButton>
            </div>
            <IonLoading isOpen={isLoading} />
        </>
    );
};

export default Step6;
