import {
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonSelect,
    IonSelectOption,
    IonGrid,
    IonRow,
    IonCol,
} from '@ionic/react';
import React from 'react';
import '../../Page.css';
/* Pullstate Store */
import { PetStore } from '../../../store/PetStore';
import { TownStore } from '../../../store/TownStore';

const Step2 = () => {
    const step = PetStore.useState((s) => s.petOnboardingStep);
    const petDoc = PetStore.useState((s) => s.petDoc);
    const townDoc = TownStore.useState((s) => s.townDoc);

    const updatePetDoc = (val, field) => {
        PetStore.update((s) => {
            s.petDoc = { ...s.petDoc, [field]: val };
        });
    };
    return (
        <div className={step === 2 ? 'steps' : 'ion-hide'}>
            <h2 className='ion-padding-horizontal onboardingTitles'>
                Tell us more about {petDoc.name && petDoc.name}
            </h2>
            <div style={{ height: '20px' }} />
            <IonGrid>
                <IonRow>
                    <IonCol className='ion-margin-vertical'>
                        <IonItem>
                            <IonLabel className='onboardingLabels' position='stacked'>
                                Species
                            </IonLabel>
                            <IonSelect
                                value={petDoc.species}
                                placeholder='Select One'
                                onIonChange={(e) => updatePetDoc(e.target.value, 'species')}
                            >
                                <IonSelectOption value='Cat'>Cat</IonSelectOption>
                                <IonSelectOption value='Dog'>Dog</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                    </IonCol>
                    <IonCol className='ion-margin-vertical'>
                        <IonItem>
                            <IonLabel className='onboardingLabels' position='stacked'>
                                Sex
                            </IonLabel>
                            <IonSelect
                                value={petDoc.gender}
                                placeholder='Select One'
                                onIonChange={(e) => updatePetDoc(e.target.value, 'gender')}
                            >
                                <IonSelectOption value='female'>Female</IonSelectOption>
                                <IonSelectOption value='male'>Male</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className='ion-margin-vertical'>
                        <IonItem>
                            <IonLabel className='onboardingLabels' position='stacked'>
                                Spayed/Neutered
                            </IonLabel>
                            <IonSelect
                                value={petDoc.neutered}
                                placeholder='Select One'
                                onIonChange={(e) => updatePetDoc(e.target.value, 'neutered')}
                            >
                                <IonSelectOption value={true}>Yes</IonSelectOption>
                                <IonSelectOption value={false}>No</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                    </IonCol>
                    <IonCol className='ion-margin-vertical'>
                        <IonItem>
                            <IonLabel className='onboardingLabels' position='stacked'>
                                Age (in years)
                            </IonLabel>
                            <IonInput
                                type='number'
                                value={petDoc.age}
                                placeholder='Age'
                                onIonChange={(e) => updatePetDoc(e.target.value, 'age')}
                            ></IonInput>
                        </IonItem>
                    </IonCol>
                </IonRow>
            </IonGrid>
            <div className='ion-padding'>
                <p className='ion-text-left'>
                    <strong>Why do we collect this information?</strong>
                </p>
                <p className='ion-text-left'>
                    This information is required for your license application in {townDoc.fullName}.
                </p>
            </div>
            <IonButton
                disabled={!petDoc.age || !petDoc.species}
                className='ion-margin'
                shape='round'
                expand='block'
                onClick={() => {
                    PetStore.update((s) => {
                        s.petOnboardingStep = 3;
                    });
                }}
            >
                {'Continue'}
            </IonButton>
        </div>
    );
};

export default Step2;
