import {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonMenuButton,
    IonTitle,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonButton,
    IonIcon,
} from '@ionic/react';
import { cardOutline } from 'ionicons/icons';
import React from 'react';
// import logo from '../assets/images/logo.jpg';
// import { useLocation } from 'react-router';

// function useQuery() {
//     return new URLSearchParams(useLocation().search);
// }
const Payment = () => {
    return (
        <IonPage mode='ios'>
            <IonHeader className='whiteBar' color='primary'>
                <IonToolbar>
                    <IonButtons slot='start'>
                        <IonMenuButton />
                        {/* <img src={headerIcon} className='headerIcon' alt='PetLicense.Online' /> */}
                    </IonButtons>
                    <IonTitle>Payment</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonHeader collapse='condense'>
                    <IonToolbar>
                        <IonTitle size='large'>Payment</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonGrid className='contentArea'>
                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardHeader>
                                    <IonCardTitle>Update your payment information</IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    <p style={{ fontSize: '1.15rem', marginBottom: '1rem' }}>
                                        For the security of your payment information,{' '}
                                        <strong>PetLicense.Online</strong> does not store your card
                                        details. Instead, we use an industry leading payment
                                        processor called <strong>Stripe</strong> (
                                        <a
                                            href='https://www.stripe.com/'
                                            alt='stripe'
                                            target='_blank'
                                            rel='noreferrer'
                                        >
                                            https://www.stripe.com/
                                        </a>
                                        ).
                                    </p>
                                    <p style={{ fontSize: '1.15rem', marginBottom: '2rem' }}>
                                        Stripe is a secure payment processor that is used by many of
                                        the largest companies in the world. Stripe is PCI Level 1
                                        compliant, the most stringent level of certification
                                        available in the payments industry, which means that they
                                        have the highest level of security available.
                                    </p>

                                    <p
                                        style={{
                                            fontSize: '1.15rem',
                                            marginBottom: '1rem',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        To update your payment information, please click the button
                                        below. You will be redirected to Stripe's secure payment
                                        portal where they will ask you for the email address you use
                                        with PetLicense.Online, and then they will send you a unique
                                        login code to access your payment information.
                                    </p>
                                    <div className='ion-padding-vertical'>
                                        <IonButton
                                            expand='block'
                                            shape='round'
                                            fill='outline'
                                            href='https://billing.stripe.com/p/login/cN24kh68n4vH7XWcMM'
                                            target='_blank'
                                        >
                                            <IonIcon icon={cardOutline} slot='start' />
                                            Update Payment Information
                                        </IonButton>
                                    </div>
                                    <p
                                        style={{
                                            fontSize: '1.15rem',
                                            marginBottom: '1rem',
                                        }}
                                    >
                                        If you have any questions, please contact us at{' '}
                                        <a
                                            href='mailto:info@petlicense.online'
                                            subject='Payment Question'
                                        >
                                            info@petlicense.online
                                        </a>
                                    </p>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default Payment;
