import {
    IonContent,
    IonIcon,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
} from '@ionic/react';

import React from 'react';
import { useLocation } from 'react-router-dom';
import {
    helpCircleOutline,
    helpCircleSharp,
    informationCircleOutline,
    informationCircleSharp,
    logOutOutline,
    logOutSharp,
    personOutline,
    personSharp,
    pawOutline,
    pawSharp,
    homeOutline,
    homeSharp,
    phonePortraitOutline,
    phonePortraitSharp,
    mailOutline,
    mailSharp,
    cardOutline,
    cardSharp,
} from 'ionicons/icons';
import './Page.css';
import PLOfull from '../assets/images/PLO_full.png';
import { auth } from '../firebase';
import { TownStore } from '../store/TownStore';

const logout = () => {
    auth.signOut();
    window.location.reload();
    console.log('log out');
};

const appPages = [
    {
        title: 'Home',
        url: '/home',
        iosIcon: homeOutline,
        mdIcon: homeSharp,
    },
    // {
    //     title: 'My Licenses',
    //     url: '/licenses',
    //     iosIcon: shieldCheckmarkOutline,
    //     mdIcon: shieldCheckmarkSharp,
    // },
    {
        title: 'My Pets',
        url: '/pets',
        iosIcon: pawOutline,
        mdIcon: pawSharp,
    },
    {
        title: 'My Profile',
        url: '/profile',
        iosIcon: personOutline,
        mdIcon: personSharp,
    },
    {
        title: 'Update Payment',
        url: '/payment',
        iosIcon: cardOutline,
        mdIcon: cardSharp,
    },
];

const Menu = () => {
    const location = useLocation();
    const townDoc = TownStore.useState((s) => s.townDoc);

    return (
        <IonMenu contentId='main'>
            <IonContent>
                <IonList id='inbox-list'>
                    <IonListHeader style={{ marginBottom: 20 }}>
                        <img src={PLOfull} alt='PetLicense.Online' />
                    </IonListHeader>
                    <IonNote>{auth.currentUser.email}</IonNote>
                    {appPages.map((appPage, index) => {
                        return (
                            <IonMenuToggle key={index} autoHide={false}>
                                <IonItem
                                    className={location.pathname === appPage.url ? 'selected' : ''}
                                    routerLink={appPage.url}
                                    routerDirection='none'
                                    lines='none'
                                    detail={false}
                                >
                                    <IonIcon
                                        slot='start'
                                        ios={appPage.iosIcon}
                                        md={appPage.mdIcon}
                                    />
                                    <IonLabel>{appPage.title}</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                        );
                    })}
                    <IonItemDivider />
                    <IonItem
                        detail='false'
                        lines='none'
                        button
                        href='mailto:info@petlicense.online?subject=Support%20Request%20-%20Brooks%20Pet%20Licenses&body=Please%20provide%20details%20of%20the%20issue%20you%20are%20experiencing%3A'
                    >
                        <IonIcon slot='start' ios={helpCircleOutline} md={helpCircleSharp} />
                        <IonLabel>Help</IonLabel>
                    </IonItem>
                    <IonItem
                        detail='false'
                        lines='none'
                        button
                        href={townDoc && townDoc.byLaw}
                        target='_blank'
                    >
                        <IonIcon
                            slot='start'
                            ios={informationCircleOutline}
                            md={informationCircleSharp}
                        />
                        <IonLabel>Bylaw</IonLabel>
                    </IonItem>
                    <IonItemDivider />
                    <IonListHeader className='ion-margin-vertical'>
                        Contact Animal Control
                    </IonListHeader>
                    <IonItem detail='false' lines='none'>
                        <IonIcon slot='start' ios={phonePortraitOutline} md={phonePortraitSharp} />
                        <IonLabel>{townDoc && townDoc.phone}</IonLabel>
                    </IonItem>

                    <IonItem detail='false' lines='none'>
                        <IonIcon slot='start' ios={mailOutline} md={mailSharp} />
                        <IonLabel>
                            <a
                                href={`mailto:${townDoc && townDoc.email}`}
                                target='_blank'
                                rel='noreferrer'
                            >
                                {townDoc && townDoc.email}
                            </a>
                        </IonLabel>
                    </IonItem>
                    <IonItemDivider />
                    <IonMenuToggle autoHide={false}>
                        <IonItem button onClick={logout} lines='none' detail={false}>
                            <IonIcon slot='start' ios={logOutOutline} md={logOutSharp} />
                            <IonLabel>Log out</IonLabel>
                        </IonItem>
                    </IonMenuToggle>
                </IonList>
            </IonContent>
        </IonMenu>
    );
};

export default Menu;
