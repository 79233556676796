import {
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import React, { useEffect } from 'react';
import './Page.css';
import firebaseApp from '../firebase';
/* Pullstate Store */
import { TownStore } from '../store/TownStore';
import { PermitStore } from '../store/PermitStore';

import BurnBan from '../components/BurnBan';
import AccountButton from '../components/AccountButton';
import Widgets from '../components/Widgets';
import Footer from '../components/Footer';
import headerIcon from '../assets/images/PLO.png';
import puppy1 from '../assets/images/puppy-leash1.jpg';

const styles = {
    hero: {
        backgroundImage: `url(${puppy1})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: 350,
        width: '100%',
    },
};
const Home = (props) => {
    const townDoc = TownStore.useState((s) => s.townDoc);
    const riskLevel = TownStore.useState((s) => s.riskLevel);
    const burnBan = TownStore.useState((s) => s.burnBan);
    const firestore = firebaseApp.firestore();
    const municipality = props.match.params.id;
    useEffect(() => {
        if (municipality) {
            const getTownDoc = () => {
                // console.log(user)
                firestore
                    .collection('municipality')
                    .doc(municipality)
                    .get()
                    .then((doc) => {
                        if (doc.exists) {
                            const data = doc.data();
                            const id = doc.id;
                            const terms = data.terms;
                            const agree = data.agree;
                            const riskLevel = data.riskLevel;
                            const burnBan = data.fireban;
                            // console.log('data ', data);
                            TownStore.update((s) => {
                                s.townDoc = { id, ...data };
                                s.riskLevel = riskLevel;
                                s.burnBan = burnBan;
                            });
                            PermitStore.update((s) => {
                                s.terms = terms;
                                s.agree = agree;
                            });
                        } else {
                            TownStore.update((s) => {
                                s.townDoc = null;
                            });
                        }
                    })
                    .catch((error) => console.log(error));
            };

            const getAnnouncements = () => {
                firestore
                    .collection('municipality')
                    .doc(municipality)
                    .collection('announcements')
                    .limit(3)
                    .get()
                    .then((querySnapshot) => {
                        const announcementsList = [];
                        querySnapshot.forEach((doc) => {
                            const id = doc.id;
                            const data = doc.data();
                            // doc.data() is never undefined for query doc snapshots
                            announcementsList.push({ id, ...data });
                            // console.log(doc.id, " => ", doc.data());
                        });
                        const annoucementsSorted = announcementsList.sort((a, b) =>
                            a.dateCreated > b.dateCreated
                                ? -1
                                : b.dateCreated > a.dateCreated
                                ? 1
                                : 0
                        );
                        TownStore.update((s) => {
                            s.announcements = annoucementsSorted;
                        });
                    });
            };
            getTownDoc();
            getAnnouncements();
        }
    }, [municipality, firestore]);

    // useEffect(() => {
    //     console.log(townDoc);
    // }, [townDoc, props]);

    if (townDoc) {
        return (
            <IonPage mode='ios'>
                <IonHeader color='primary'>
                    <IonToolbar>
                        <IonButtons slot='start'>
                            <IonMenuButton />{' '}
                            <img src={headerIcon} className='headerIcon' alt='PetLicense.Online' />
                        </IonButtons>

                        <IonTitle>{townDoc && townDoc.fullName} - PetLicense.Online</IonTitle>
                        <IonButtons slot='end'>
                            <AccountButton icon={true} color={'primary'} />
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    <IonGrid className='ion-no-padding'>
                        <IonRow style={styles.hero}>
                            <IonCol></IonCol>
                        </IonRow>
                    </IonGrid>
                    <IonGrid className='contentHolder'>
                        <IonRow>
                            <IonCol>
                                <p className='ion-text-center'>
                                    <img
                                        style={{ width: 250 }}
                                        src={townDoc && townDoc.logo}
                                        alt={townDoc && townDoc.fullName}
                                    />
                                </p>
                            </IonCol>
                        </IonRow>
                        {riskLevel === 5 || burnBan ? (
                            <IonRow>
                                <IonCol>
                                    <BurnBan />
                                </IonCol>
                            </IonRow>
                        ) : null}
                        <IonRow>
                            <IonCol className='ion-padding-horizontal'>
                                <h2 className='ion-text-center headingText'>Apply for a Permit</h2>
                                <p className='ion-text-center'>
                                    <AccountButton />
                                </p>
                            </IonCol>
                        </IonRow>
                        <Widgets />
                    </IonGrid>
                    <Footer />
                </IonContent>
            </IonPage>
        );
    } else {
        return (
            <IonPage mode='ios'>
                <IonHeader color='primary'>
                    <IonToolbar>
                        <IonTitle>PetLicense.Online</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    <IonLoading />
                </IonContent>
            </IonPage>
        );
    }
};

export default Home;
