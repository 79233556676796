import ReactSpeedometer from "react-d3-speedometer"
import React from 'react';
/* Pullstate Store */
import { TownStore } from "../store/TownStore";
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle } from "@ionic/react";
  
const RiskGauge = (props) => {    
    const townDoc = TownStore.useState(s => s.townDoc);
  
    const labels = [
        {
          text: "Low",
          position: "OUTSIDE",
          // color: isDark ? '#fff' : '#555',
          color: '#555'
        },
        {
          text: "Moderate",
          position: "OUTSIDE",
          // color: isDark ? '#fff' : '#555',
          color: '#555'
        },
        {
          text: "High",
          position: "OUTSIDE",
          // color: isDark ? '#fff' : '#555',
          color: '#555',
          fontSize: "19px",
        },
        {
          text: "Very High",
          position: "OUTSIDE",
          // color: isDark ? '#fff' : '#555',
          color: '#555'
        },
        {
          text: "Burn Ban",
          position: "OUTSIDE",
          // color: isDark ? '#fff' : '#555',
          color: '#555'
        },
      ]
    return (
        <IonCard>
          <div style={{
            width: "100%",
            height: "150px",
            margin: '20px auto 25px auto',
          }}>
            <div className="ion-text-center"><ReactSpeedometer
                paddingVertical={10}
                maxValue={5}
                value={townDoc ? townDoc.riskLevel - 0.5 : 0}
                needleColor={'#5260ff'}
                needleHeightRatio={0.45}
                segments={5}
                // customSegmentStops={[0, 1.5, 2.5, 3.5, 5.0]}
                segmentColors={[
                    "#5260ff",
                    // "#3dc2ff",
                    "#2dd36f",
                    "#ffc409",
                    "#ff6600",
                    "#ff0000",
                ]}
                customSegmentLabels={labels}
                currentValueText={`${townDoc && townDoc.riskLevel}`}
            /></div>
        </div>
          <IonCardHeader>
            <IonCardSubtitle>Current</IonCardSubtitle>
            <IonCardTitle>Fire Risk Level: {townDoc && townDoc.riskLevel}</IonCardTitle>
          </IonCardHeader>
            <IonCardContent>
            {townDoc !== null && townDoc.fireban === true ? 
              <p>The town has currently instituted a fire ban. No burns are permitted. Please monitor this area for updates.</p> :
              <p>A level {townDoc && townDoc.riskLevel} fire danger rating indicates burning should be carried out with caution.</p>
            }
            </IonCardContent>
          
        </IonCard>
        
    );
};

export default RiskGauge;
  