import {
    IonButtons,
    IonButton,
    IonHeader,
    IonToolbar,
    IonModal,
    IonIcon,
    IonTitle,
} from '@ionic/react';
import React from 'react';
import '../Page.css';
import OnboardingSteps from './PetOnboardingSteps';
/* Pullstate Store */
import { PetStore } from '../../store/PetStore';
import { chevronBackOutline, closeOutline } from 'ionicons/icons';

const PetOnboardingModal = () => {
    const step = PetStore.useState((s) => s.petOnboardingStep);
    const context = PetStore.useState((s) => s.context);

    const close = () => {
        PetStore.update((s) => {
            s.petOnboardingModal = false;
        });
    };
    const updateStep = (inc) => {
        if (inc !== 0) {
            PetStore.update((s) => {
                s.petOnboardingStep = s.petOnboardingStep + inc;
            });
        } else {
            PetStore.update((s) => {
                s.petOnboardingStep = 0;
            });
        }
    };

    return (
        <IonModal isOpen={step > 0} onDidDismiss={close} mode='ios'>
            <IonHeader>
                <IonToolbar>
                    {step > 1 && step < 6 ? (
                        <IonButtons>
                            <IonButton onClick={() => updateStep(-1)}>
                                <IonIcon icon={chevronBackOutline} slot='start' />
                                Back
                            </IonButton>
                        </IonButtons>
                    ) : null}
                    <IonTitle>{context === 'update' ? 'Update' : 'Add'} a Pet</IonTitle>
                    {step !== 6 ? (
                        <IonButtons slot='end'>
                            <IonButton
                                onClick={() => {
                                    updateStep(0);
                                    close();
                                }}
                            >
                                <IonIcon icon={closeOutline} slot='icon-only' />
                            </IonButton>
                        </IonButtons>
                    ) : null}
                </IonToolbar>
            </IonHeader>
            <OnboardingSteps />
        </IonModal>
    );
};

export default PetOnboardingModal;
