import { IonContent } from '@ionic/react';
import React from 'react';
import '../Page.css';
/* Pullstate Store */
// import { UserStore } from "../../store/UserStore";
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import Step4 from './Steps/Step4';
import Step5 from './Steps/Step5';

const OnboardingSteps = () => {
    // const step = UserStore.useState(s => s.onboardingStep);

    // const userDoc = UserStore.useState(s => s.userDoc);

    // // useEffect(() => {
    // //   console.log(userDoc);
    // // }, [userDoc]);
    // // useEffect(() => {
    // //   console.log(step);
    // // }, [step]);
    return (
        <IonContent>
            {/****** First Name Area ******/}
            <Step1 />
            {/****** Last Name Area ******/}
            <Step2 />
            {/****** Phone Number Area ******/}
            <Step3 />
            {/****** Home Address Area ******/}
            <Step4 />
            {/****** Confirmation Area ******/}
            <Step5 />
        </IonContent>
    );
};

export default OnboardingSteps;
