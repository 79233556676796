import React, { useEffect } from 'react';
import '../../Page.css';
/* Pullstate Store */
import { PermitStore } from '../../../store/PermitStore';
import { Checkmark } from 'react-checkmark';
import { IonButton } from '@ionic/react';

const Step7 = () => {
    const step = PermitStore.useState((s) => s.applicationStep);

    useEffect(() => {
        PermitStore.update((s) => {
            s.application = s.initialApplicationState;
        });
    }, []);
    return (
        <div className={step === 7 ? 'steps' : 'ion-hide'}>
            <>
                <h2 className='ion-padding-horizontal onboardingTitles ion-text-center'>
                    Your license purchase was successful!
                </h2>
                <p className='ion-text-center'>
                    <Checkmark color='#2dd36f' size='xxLarge' />
                </p>
                <p className='ion-text-center'>
                    <IonButton shape='round' color='success' href='/pets'>
                        See My Pets
                    </IonButton>
                </p>
            </>
        </div>
    );
};

export default Step7;
