import {
    IonGrid,
    IonRow,
    IonCol,
    IonLoading,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonImg,
} from '@ionic/react';
import React from 'react';
import './Page.css';
/* Pullstate Store */
import { UserStore } from '../store/UserStore';
import { PetStore } from '../store/PetStore';
import { PermitStore } from '../store/PermitStore';
import ApplicationModal from './Application/ApplicationModal';
import PetOnboardingModal from './PetOnboarding/PetOnboardingModal';
import dogWithTag from '../assets/images/dogWithTag.jpg';
import dogAndCat from '../assets/images/dogAndCat.jpg';
import Onboarding from './Onboarding/Onboarding';

const Start = () => {
    const userDoc = UserStore.useState((s) => s.userDoc);
    const pets = PetStore.useState((s) => s.pets);

    const addPet = () => {
        PetStore.update((s) => {
            s.petOnboardingStep = 1;
        });
    };

    const getLicense = () => {
        PermitStore.update((s) => {
            s.applicationStep = 1;
        });
    };

    if (userDoc) {
        return (
            <IonGrid>
                <IonRow>
                    <IonCol>
                        {userDoc.complete ? (
                            <h2 className='headingText ion-text-center'>
                                Welcome, {userDoc.firstName}!
                            </h2>
                        ) : (
                            <>
                                <Onboarding />
                            </>
                        )}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol sizeXs={12} sizeMd={6}>
                        <IonCard
                            disabled={!pets.length || !userDoc.complete}
                            button
                            onClick={getLicense}
                        >
                            <IonImg src={dogWithTag} />
                            <IonCardHeader>
                                <IonCardTitle className='ion-text-center'>
                                    Get a License
                                </IonCardTitle>
                            </IonCardHeader>
                        </IonCard>
                    </IonCol>
                    <IonCol sizeXs={12} sizeMd={6}>
                        <IonCard button disabled={!userDoc.complete} onClick={addPet}>
                            <IonImg src={dogAndCat} />
                            <IonCardHeader>
                                <IonCardTitle className='ion-text-center'>
                                    Add {pets.length ? 'Another' : 'Your'} Pet
                                </IonCardTitle>
                            </IonCardHeader>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <PetOnboardingModal />
                <ApplicationModal />
            </IonGrid>
        );
    } else {
        return <IonLoading isOpen={true} />;
    }
};

export default Start;
