import {
    IonAlert,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import React, { useState } from 'react';
import './Page.css';
/* Pullstate Store */
import { UserStore } from '../store/UserStore';
import ApplicationModal from '../components/Application/ApplicationModal';
import {
    addCircleOutline,
    createOutline,
    pawOutline,
    // qrCodeOutline,
    shieldCheckmarkSharp,
    warningOutline,
} from 'ionicons/icons';
import { PetStore } from '../store/PetStore';
import PetOnboardingModal from '../components/PetOnboarding/PetOnboardingModal';
import ReactCardFlip from 'react-card-flip';
// import QRCode from 'react-qr-code';
import { firestore } from '../firebase';
import { toast } from '../components/Toast/Toast';

const Pets = () => {
    const userDoc = UserStore.useState((s) => s.userDoc);
    const pets = PetStore.useState((s) => s.pets);
    const [currentPet, setCurrentPet] = useState(null);
    const [isFlipped, setIsFlipped] = useState(null);
    const [lostAlert, setLostAlert] = useState(false);
    const [foundAlert, setFoundAlert] = useState(false);
    const filteredPets = pets.filter((p) => !p.deceased);

    const editPet = () => {
        PetStore.update((s) => {
            s.context = 'update';
            s.petDoc = currentPet;
            s.petOnboardingModal = true;
            s.petOnboardingStep = 1;
        });
    };
    const handleReportClick = (lost) => {
        console.log('lost');
        firestore
            .collection('alerts')
            .add({
                pet: currentPet,
                user: userDoc,
                lost: lost,
                lastAddress: userDoc.fullAddress,
                reportedTime: Date.now(),
            })
            .then(() => {
                firestore
                    .collection('users')
                    .doc(userDoc.uid)
                    .collection('pets')
                    .doc(currentPet.id)
                    .update({
                        lost: lost,
                    });
                toast(
                    `A notification has been sent to Animal Control that ${currentPet.name} is missing.`
                );
            })
            .then(() => {
                window.location.reload(true);
            })
            .catch((error) => {
                console.log(error.message);
                toast('There was an error submitting your lost pet alert. Please try again.');
            });
    };
    if (userDoc) {
        return (
            <IonPage mode='ios'>
                <IonHeader className='whiteBar' color='primary'>
                    <IonToolbar>
                        <IonButtons slot='start'>
                            <IonMenuButton />
                            {/* <img src={headerIcon} className='headerIcon' alt='PetLicense.Online' /> */}
                        </IonButtons>
                        <IonTitle>My Pets</IonTitle>
                        <IonButtons slot='end'>
                            <IonButton
                                slot='icon-only'
                                onClick={() => {
                                    PetStore.update((s) => {
                                        s.petOnboardingModal = true;
                                        s.petOnboardingStep = 1;
                                    });
                                }}
                            >
                                <IonIcon icon={addCircleOutline} />
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    <IonHeader color='primary' collapse='condense'>
                        <IonToolbar>
                            <IonTitle size='large'>My Pets</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonGrid className='contentHolder'>
                        <IonRow>
                            {filteredPets.length > 0 &&
                                filteredPets.map((pet, i) => {
                                    if (pet.decease) {
                                        return null;
                                    } else {
                                        return (
                                            <IonCol sizeXs='12' sizeMd='6' sizeLg='4' key={i}>
                                                <ReactCardFlip
                                                    isFlipped={isFlipped === i}
                                                    flipDirection='horizontal'
                                                >
                                                    <IonCard
                                                        mode='ios'
                                                        button
                                                        // onClick={() => handleOpen(pet)}
                                                        onClick={() => {
                                                            setCurrentPet(pet);
                                                            setIsFlipped(i);
                                                        }}
                                                        style={{
                                                            display:
                                                                isFlipped === i
                                                                    ? 'none'
                                                                    : 'inherit',
                                                        }}
                                                    >
                                                        {/* <p className="ion-text-center"><IonIcon icon={documentTextOutline} color="primary" className="bigIcon" /></p> */}
                                                        {pet.image ? (
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    height: 300,
                                                                    overflow: 'hidden',
                                                                }}
                                                            >
                                                                <p
                                                                    className='ion-no-padding ion-no-margin'
                                                                    style={{ textAlign: 'center' }}
                                                                >
                                                                    <img
                                                                        src={pet.image}
                                                                        alt='pet'
                                                                        style={{
                                                                            minHeight: 300,
                                                                            width: 'auto',
                                                                        }}
                                                                    />
                                                                </p>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    height: 300,
                                                                    overflow: 'hidden',
                                                                    backgroundColor: '#fafafa',
                                                                }}
                                                            >
                                                                <p className='ion-text-center'>
                                                                    <IonIcon
                                                                        icon={pawOutline}
                                                                        color='primary'
                                                                        className='bigIcon'
                                                                        style={{ marginTop: 90 }}
                                                                    />
                                                                </p>
                                                            </div>
                                                        )}
                                                        <IonCardHeader>
                                                            <IonCardTitle className='ion-text-center'>
                                                                {pet.name}, {pet.age} years old
                                                            </IonCardTitle>
                                                            <IonCardSubtitle className='ion-text-center ion-padding-vertical'>
                                                                {pet.species}
                                                                {', ' + pet.breed}
                                                            </IonCardSubtitle>
                                                        </IonCardHeader>
                                                        <IonCardContent>
                                                            <IonGrid>
                                                                <IonRow>
                                                                    <IonCol>
                                                                        <p className='ion-text-center'>
                                                                            <IonIcon
                                                                                icon={
                                                                                    shieldCheckmarkSharp
                                                                                }
                                                                                size='large'
                                                                                color={
                                                                                    pet.licensed
                                                                                        ? 'warning'
                                                                                        : 'light'
                                                                                }
                                                                            />
                                                                        </p>
                                                                        <p className='ion-text-center ion-no-margin ion-no-padding'>
                                                                            Licensed
                                                                        </p>
                                                                    </IonCol>
                                                                    <IonCol>
                                                                        <p className='ion-text-center'>
                                                                            <IonIcon
                                                                                icon={
                                                                                    shieldCheckmarkSharp
                                                                                }
                                                                                size='large'
                                                                                color={
                                                                                    pet.immunisation
                                                                                        ? 'success'
                                                                                        : 'light'
                                                                                }
                                                                            />
                                                                        </p>
                                                                        <p className='ion-text-center ion-no-margin ion-no-padding'>
                                                                            Immunized
                                                                        </p>
                                                                    </IonCol>
                                                                    <IonCol>
                                                                        <p className='ion-text-center'>
                                                                            <IonIcon
                                                                                icon={
                                                                                    shieldCheckmarkSharp
                                                                                }
                                                                                size='large'
                                                                                color={
                                                                                    pet.neutered
                                                                                        ? 'success'
                                                                                        : 'light'
                                                                                }
                                                                            />
                                                                        </p>
                                                                        <p className='ion-text-center ion-no-margin ion-no-padding'>
                                                                            {pet.gender === 'male'
                                                                                ? 'Neutered'
                                                                                : 'Spayed'}
                                                                        </p>
                                                                    </IonCol>
                                                                </IonRow>
                                                            </IonGrid>
                                                        </IonCardContent>
                                                    </IonCard>
                                                    <IonCard
                                                        mode='ios'
                                                        button
                                                        // onClick={() => handleOpen(pet)}
                                                        onClick={() => setIsFlipped(null)}
                                                        style={{
                                                            display:
                                                                isFlipped === i
                                                                    ? 'inherit'
                                                                    : 'none',
                                                        }}
                                                    >
                                                        {/* <p className="ion-text-center"><IonIcon icon={documentTextOutline} color="primary" className="bigIcon" /></p> */}

                                                        {/* <div style={{ height: 2.5 }} />
                                                <p className='ion-text-center'>
                                                    <QRCode size={280} value={pet.id} />
                                                </p> */}
                                                        <IonCardHeader className=''>
                                                            <IonCardTitle className='ion-text-center'>
                                                                {pet.name}, {pet.age} years old
                                                            </IonCardTitle>
                                                            <IonCardSubtitle className='ion-text-center ion-padding-vertical'>
                                                                {pet.species}
                                                                {', ' + pet.breed}
                                                            </IonCardSubtitle>
                                                        </IonCardHeader>

                                                        <IonCardContent>
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    height: 300,
                                                                }}
                                                            >
                                                                {/* <IonButton
                                                            expand='block'
                                                            className='ion-margin-bottom'
                                                        >
                                                            <IonIcon
                                                                slot='start'
                                                                icon={qrCodeOutline}
                                                            />
                                                            Show QR Code
                                                        </IonButton> */}
                                                                <IonButton
                                                                    className='ion-margin-bottom'
                                                                    fill='outline'
                                                                    expand='block'
                                                                    onClick={() => editPet()}
                                                                >
                                                                    <IonIcon
                                                                        slot='start'
                                                                        icon={createOutline}
                                                                    />
                                                                    Edit {pet.name}'s Profile
                                                                </IonButton>
                                                                {pet && !pet.lost ? (
                                                                    <IonButton
                                                                        fill='solid'
                                                                        color='danger'
                                                                        expand='block'
                                                                        className='ion-margin-bottom'
                                                                        onClick={() =>
                                                                            setLostAlert(true)
                                                                        }
                                                                    >
                                                                        <IonIcon
                                                                            slot='start'
                                                                            icon={warningOutline}
                                                                        />
                                                                        Mark {pet.name} as Lost
                                                                    </IonButton>
                                                                ) : (
                                                                    <IonButton
                                                                        fill='solid'
                                                                        color='success'
                                                                        expand='block'
                                                                        className='ion-margin-bottom'
                                                                        onClick={() =>
                                                                            setFoundAlert(true)
                                                                        }
                                                                    >
                                                                        <IonIcon
                                                                            slot='start'
                                                                            icon={warningOutline}
                                                                        />
                                                                        Mark {pet.name} as Found
                                                                    </IonButton>
                                                                )}
                                                                <IonAlert
                                                                    isOpen={foundAlert}
                                                                    onDidDismiss={() =>
                                                                        setFoundAlert(false)
                                                                    }
                                                                    header={'Please be advised'}
                                                                    message={`By reporting ${
                                                                        currentPet
                                                                            ? currentPet.name
                                                                            : null
                                                                    } as found, you will notify by-law
                                            enforcement & animal control immediately. Use this feature responsibly and if you have any questions,
                                            reach out to Animal Control directly.`}
                                                                    buttons={[
                                                                        {
                                                                            text: 'Cancel',
                                                                            role: 'cancel',
                                                                            cssClass: 'secondary',
                                                                            handler: () => {
                                                                                console.log(
                                                                                    'Confirm Cancel'
                                                                                );
                                                                            },
                                                                        },
                                                                        {
                                                                            text: 'Confirm',
                                                                            handler: () =>
                                                                                handleReportClick(
                                                                                    false
                                                                                ),
                                                                        },
                                                                    ]}
                                                                />
                                                                <IonAlert
                                                                    isOpen={lostAlert}
                                                                    onDidDismiss={() =>
                                                                        setLostAlert(false)
                                                                    }
                                                                    header={'Please be advised'}
                                                                    message={`By reporting ${
                                                                        currentPet
                                                                            ? currentPet.name
                                                                            : null
                                                                    } as lost, you will notify by-law
                                            enforcement & animal control immediately. Use this feature responsibly and if you have any questions,
                                            reach out to Animal Control directly.`}
                                                                    buttons={[
                                                                        {
                                                                            text: 'Cancel',
                                                                            role: 'cancel',
                                                                            cssClass: 'secondary',
                                                                            handler: () => {
                                                                                console.log(
                                                                                    'Confirm Cancel'
                                                                                );
                                                                            },
                                                                        },
                                                                        {
                                                                            text: 'Confirm',
                                                                            handler: () =>
                                                                                handleReportClick(
                                                                                    true
                                                                                ),
                                                                        },
                                                                    ]}
                                                                />
                                                            </div>
                                                            <IonGrid>
                                                                <IonRow>
                                                                    <IonCol>
                                                                        <p className='ion-text-center'>
                                                                            <IonIcon
                                                                                icon={
                                                                                    shieldCheckmarkSharp
                                                                                }
                                                                                size='large'
                                                                                color={
                                                                                    pet.licensed
                                                                                        ? 'warning'
                                                                                        : 'light'
                                                                                }
                                                                            />
                                                                        </p>
                                                                        <p className='ion-text-center ion-no-margin ion-no-padding'>
                                                                            Licensed
                                                                        </p>
                                                                    </IonCol>
                                                                    <IonCol>
                                                                        <p className='ion-text-center'>
                                                                            <IonIcon
                                                                                icon={
                                                                                    shieldCheckmarkSharp
                                                                                }
                                                                                size='large'
                                                                                color={
                                                                                    pet.immunisation
                                                                                        ? 'success'
                                                                                        : 'light'
                                                                                }
                                                                            />
                                                                        </p>
                                                                        <p className='ion-text-center ion-no-margin ion-no-padding'>
                                                                            Immunized
                                                                        </p>
                                                                    </IonCol>
                                                                    <IonCol>
                                                                        <p className='ion-text-center'>
                                                                            <IonIcon
                                                                                icon={
                                                                                    shieldCheckmarkSharp
                                                                                }
                                                                                size='large'
                                                                                color={
                                                                                    pet.neutered
                                                                                        ? 'success'
                                                                                        : 'light'
                                                                                }
                                                                            />
                                                                        </p>
                                                                        <p className='ion-text-center ion-no-margin ion-no-padding'>
                                                                            {pet.gender === 'male'
                                                                                ? 'Neutered'
                                                                                : 'Spayed'}
                                                                        </p>
                                                                    </IonCol>
                                                                </IonRow>
                                                            </IonGrid>
                                                        </IonCardContent>
                                                    </IonCard>
                                                </ReactCardFlip>
                                            </IonCol>
                                        );
                                    }
                                })}
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonButton
                                    expand='block'
                                    onClick={() => {
                                        PetStore.update((s) => {
                                            s.petOnboardingModal = true;
                                            s.petOnboardingStep = 1;
                                        });
                                    }}
                                >
                                    <IonIcon icon={addCircleOutline} style={{ marginRight: 10 }} />{' '}
                                    Add Another
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <ApplicationModal />
                </IonContent>
                <PetOnboardingModal />
            </IonPage>
        );
    }
    if (!userDoc) {
        return (
            <IonPage>
                <IonLoading isOpen={true} />
            </IonPage>
        );
    }
};

export default Pets;
