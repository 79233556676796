import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import React, { useState } from 'react';
import QRCode from 'react-qr-code';
import './Page.css';
/* Pullstate Store */
import { UserStore } from '../store/UserStore';
import ApplicationModal from '../components/Application/ApplicationModal';

import { closeOutline, pawOutline, shieldCheckmarkSharp } from 'ionicons/icons';
import { PetStore } from '../store/PetStore';

const Licenses = () => {
    const userDoc = UserStore.useState((s) => s.userDoc);
    const pets = PetStore.useState((s) => s.pets);
    const [modalOpen, setModalOpen] = useState(false);
    const [pet, setPet] = useState(null);

    const handleOpen = (val) => {
        console.log(val);
        setPet(val);
        setModalOpen(!modalOpen);
    };

    if (userDoc) {
        return (
            <IonPage mode='ios'>
                <IonHeader className='whiteBar' color='primary'>
                    <IonToolbar>
                        <IonButtons slot='start'>
                            <IonMenuButton />
                            {/* <img src={headerIcon} className='headerIcon' alt='PetLicense.Online' /> */}
                        </IonButtons>
                        <IonTitle>My Pets</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    <IonHeader color='primary' collapse='condense'>
                        <IonToolbar>
                            <IonTitle size='large'>My Pets</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonGrid className='contentHolder'>
                        <IonRow>
                            {pets.length > 0 &&
                                pets.map((pet, i) => (
                                    <IonCol sizeXs='12' sizeMd='6' sizeLg='4' key={i}>
                                        <IonCard mode='ios' button onClick={() => handleOpen(pet)}>
                                            {/* <p className="ion-text-center"><IonIcon icon={documentTextOutline} color="primary" className="bigIcon" /></p> */}
                                            <p className='ion-text-center'>
                                                <IonIcon
                                                    icon={pawOutline}
                                                    color='primary'
                                                    className='bigIcon'
                                                />
                                            </p>
                                            <IonCardHeader>
                                                <IonCardTitle className='ion-text-center'>
                                                    {pet.name}, {pet.age} years old
                                                </IonCardTitle>
                                                <IonCardSubtitle className='ion-text-center'>
                                                    {pet.species}
                                                    {', ' + pet.breed}
                                                </IonCardSubtitle>
                                            </IonCardHeader>
                                            <IonCardContent>
                                                <p className='ion-text-center'>
                                                    <IonIcon
                                                        icon={shieldCheckmarkSharp}
                                                        size='large'
                                                        color={pet.licensed ? 'warning' : 'light'}
                                                    />
                                                </p>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                ))}
                        </IonRow>
                    </IonGrid>
                    <ApplicationModal />
                </IonContent>
                {pet && (
                    <IonModal
                        isOpen={modalOpen}
                        onDidDismiss={() => setModalOpen(false)}
                        mode='ios'
                    >
                        <IonHeader>
                            <IonToolbar>
                                <IonTitle>{pet && pet.name}</IonTitle>
                                <IonButtons slot='end'>
                                    <IonButton
                                        onClick={() => {
                                            setPet(null);
                                            setModalOpen(!modalOpen);
                                        }}
                                    >
                                        <IonIcon icon={closeOutline} slot='icon-only' />
                                    </IonButton>
                                </IonButtons>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent>
                            <p className='ion-text-center'>
                                <QRCode value={pet.id} />
                            </p>
                            <IonList>
                                <IonItem lines='none'>
                                    <IonLabel
                                        className='ion-text-wrap onboardingLabels'
                                        position='stacked'
                                    >
                                        Breed
                                    </IonLabel>
                                    <p>{pet.breed}</p>
                                </IonItem>

                                <IonItem lines='none'>
                                    <IonLabel
                                        className='ion-text-wrap onboardingLabels'
                                        position='stacked'
                                    >
                                        Immunisation up-to-date?
                                    </IonLabel>
                                    <p>{pet.immunisation ? 'Yes' : 'No'}</p>
                                </IonItem>
                                <IonItem lines='none'>
                                    <IonLabel
                                        className='ion-text-wrap onboardingLabels'
                                        position='stacked'
                                    >
                                        Microchip?
                                    </IonLabel>
                                    <p>{pet.microchip ? 'Yes' : 'No'}</p>
                                </IonItem>
                                <IonItem lines='none'>
                                    <IonLabel
                                        className='ion-text-wrap onboardingLabels'
                                        position='stacked'
                                    >
                                        Identifying Markings
                                    </IonLabel>
                                    <p>{pet.identifiers}</p>
                                </IonItem>
                            </IonList>
                        </IonContent>
                    </IonModal>
                )}
            </IonPage>
        );
    }
    if (!userDoc) {
        return (
            <IonPage>
                <IonLoading isOpen={true} />
            </IonPage>
        );
    }
};

export default Licenses;
