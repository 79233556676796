import {
    IonButton,
    IonCol,
    IonGrid,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonSelect,
    IonSelectOption,
} from '@ionic/react';
import React from 'react';
import '../../Page.css';
/* Pullstate Store */
import { PetStore } from '../../../store/PetStore';
import 'react-phone-input-2/lib/style.css';

const Step3 = () => {
    const step = PetStore.useState((s) => s.petOnboardingStep);
    const petDoc = PetStore.useState((s) => s.petDoc);

    const updatePetDoc = (val, field) => {
        // console.log('update', val, field);
        PetStore.update((s) => {
            s.petDoc = { ...s.petDoc, [field]: val };
        });
    };
    return (
        <div className={step === 3 ? 'steps' : 'ion-hide'}>
            <h2 className='ion-padding-horizontal onboardingTitles'>Can you also tell us...</h2>
            <div style={{ height: '20px' }} />
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel className='onboardingLabels' position='stacked'>
                                What is {petDoc.gender === 'male' ? 'his' : 'her'} size?
                            </IonLabel>
                            <IonSelect
                                value={petDoc.size}
                                placeholder='Select One'
                                onIonChange={(e) => updatePetDoc(e.target.value, 'size')}
                            >
                                <IonSelectOption value={'small'}>Small</IonSelectOption>
                                <IonSelectOption value={'medium'}>Medium</IonSelectOption>
                                <IonSelectOption value={'large'}>Large</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel className='onboardingLabels' position='stacked'>
                                What is {petDoc.gender === 'male' ? 'His' : 'Her'} Breed
                            </IonLabel>
                            <IonInput
                                type='text'
                                value={petDoc.breed}
                                placeholder='Enter breed here'
                                onIonChange={(e) => updatePetDoc(e.target.value, 'breed')}
                            ></IonInput>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel className='onboardingLabels' position='stacked'>
                                What is {petDoc.gender === 'male' ? 'His' : 'Her'} Colour{' '}
                            </IonLabel>
                            <IonInput
                                type='text'
                                value={petDoc.color}
                                placeholder='Enter color here'
                                onIonChange={(e) => updatePetDoc(e.target.value, 'color')}
                            ></IonInput>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel className='onboardingLabels' position='stacked'>
                                Is {petDoc.gender === 'male' ? 'he' : 'she'} microchipped
                            </IonLabel>
                            <IonSelect
                                value={petDoc.microchip}
                                placeholder='Select One'
                                onIonChange={(e) => updatePetDoc(e.target.value, 'microchip')}
                            >
                                <IonSelectOption value={true}>Yes</IonSelectOption>
                                <IonSelectOption value={false}>No</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel className='ion-text-wrap onboardingLabels' position='stacked'>
                                Does {petDoc.gender === 'male' ? 'he' : 'she'} have any identifying
                                markings <small>(optional)</small>
                            </IonLabel>
                            <IonInput
                                type='text'
                                value={petDoc.identifiers}
                                placeholder='Enter any markings here'
                                onIonChange={(e) => updatePetDoc(e.target.value, 'identifiers')}
                            ></IonInput>
                        </IonItem>
                    </IonCol>
                </IonRow>
            </IonGrid>
            <div className='ion-padding'>
                <p className='ion-text-left'>
                    <strong>How will we use this information?</strong>
                </p>
                <p className='ion-text-left'>
                    Having this information allows us to provide a detailed description and properly
                    identify {petDoc.name} so we can help reunited{' '}
                    {petDoc.gender === 'male' ? 'him' : 'her'} if{' '}
                    {petDoc.gender === 'male' ? 'he' : 'she'} is ever lost.
                </p>
            </div>
            <IonButton
                disabled={petDoc.microchip === null}
                className='ion-margin'
                shape='round'
                expand='block'
                onClick={() => {
                    PetStore.update((s) => {
                        s.petOnboardingStep = 4;
                    });
                }}
            >
                {'Continue'}
            </IonButton>
        </div>
    );
};

export default Step3;
