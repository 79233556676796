import { IonContent } from '@ionic/react';
import React from 'react';
import '../Page.css';
/* Pullstate Store */
// import { PetStore } from '../../store/PetStore';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import Step4 from './Steps/Step4';
import Step5 from './Steps/Step5';
import Step6 from './Steps/Step6';

const PetOnboardingSteps = () => {
    // const step = PetStore.useState((s) => s.petOnboardingStep);

    // const petDoc = PetStore.useState((s) => s.petDoc);

    // // useEffect(() => {
    // //     console.log(petDoc);
    // // }, [petDoc]);
    // // useEffect(() => {
    // //     console.log(step);
    // // }, [step]);
    return (
        <IonContent>
            {/****** First Name Area ******/}
            <Step1 />
            {/****** Last Name Area ******/}
            <Step2 />
            {/****** Phone Number Area ******/}
            <Step3 />
            {/****** Home Address Area ******/}
            <Step4 />
            {/****** Confirmation Area ******/}
            <Step5 />
            {/****** CTA Area ******/}
            <Step6 />
        </IonContent>
    );
};

export default PetOnboardingSteps;
