import {
    IonContent,
    IonPage,
    IonImg,
    IonLoading,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonRow,
    IonCol,
    IonText,
    IonGrid,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
} from '@ionic/react';
import React, { useState } from 'react';
import firebaseApp from '../firebase';
import PLOfull from '../assets/images/PLO_full.png';
import { TownStore } from '../store/TownStore';

const Register = ({ history }) => {
    const [email, setEmail] = useState(``);
    const [password, setPassword] = useState(``);
    const [confirmedPassword, setConfirmedPassword] = useState(``);
    const [error, setError] = useState(``);
    const [showLoading, setShowLoading] = useState(false);
    const townDoc = TownStore.useState((s) => s.townDoc);

    const createUserWithEmailAndPasswordHandler = (email, password) => {
        setError(``);

        if (email.length < 4) {
            setError(`Name must be at least 4 characters.`);
        }
        if (password.length < 4) {
            setError(`Password must be at least 4 characters.`);
        }
        if (password !== confirmedPassword) {
            setError(`You passwords as entered do not match. Please try again.`);
        }
        if (error === ``) {
            firebaseApp
                .auth()
                .createUserWithEmailAndPassword(email, password)
                .then((user) => {
                    console.log('registration success');
                    user.sendEmailVerification()
                        .then(() => {
                            console.log('email verification sent');
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                })
                .catch(function () {
                    console.log('verification email NOT sent');
                });
        }
    };

    return (
        <IonPage mode='ios'>
            <IonLoading
                cssClass='my-custom-class'
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Please wait...'}
                duration={5000}
            />
            <IonContent>
                <IonGrid className='contentArea ion-padding-horizontal'>
                    <IonRow>
                        <IonCol>
                            <div style={{ height: 50 }} />
                            <IonImg className='ion-padding' style={{ height: 100 }} src={PLOfull} />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonImg className='ion-padding' src={townDoc.logo} />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonCard mode='ios'>
                                <IonCardHeader className='ion-margin-top'>
                                    <IonCardSubtitle>Create a new</IonCardSubtitle>
                                    <IonCardTitle>Account</IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent className='ion-padding-horizontal'>
                                    <div>
                                        <IonList>
                                            <IonItem>
                                                <IonLabel position='floating'>Email</IonLabel>
                                                <IonInput
                                                    value={email}
                                                    placeholder='Enter Email'
                                                    type='email'
                                                    pattern='email'
                                                    onIonChange={(e) => setEmail(e.detail.value)}
                                                ></IonInput>
                                            </IonItem>
                                            <IonItem>
                                                <IonLabel position='floating'>Password</IonLabel>
                                                <IonInput
                                                    value={password}
                                                    placeholder='Enter Password'
                                                    type='password'
                                                    onIonChange={(e) => setPassword(e.detail.value)}
                                                ></IonInput>
                                            </IonItem>
                                            <IonItem>
                                                <IonLabel position='floating'>
                                                    Confirm Password
                                                </IonLabel>
                                                <IonInput
                                                    value={confirmedPassword}
                                                    placeholder='Re-enter Password'
                                                    type='password'
                                                    onIonChange={(e) =>
                                                        setConfirmedPassword(e.detail.value)
                                                    }
                                                ></IonInput>
                                            </IonItem>
                                        </IonList>
                                        <br />
                                        <IonButton
                                            expand='block'
                                            color='primary'
                                            onClick={() =>
                                                createUserWithEmailAndPasswordHandler(
                                                    email,
                                                    password
                                                )
                                            }
                                        >
                                            Register
                                        </IonButton>
                                    </div>
                                    <IonRow>
                                        <IonCol>
                                            {error !== '' && (
                                                <IonText color='danger'>
                                                    <p className='ion-padding-vertical'>{error}</p>
                                                </IonText>
                                            )}
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonButton
                                                routerLink='/'
                                                color='primary'
                                                fill='clear'
                                                expand='block'
                                            >
                                                Login
                                            </IonButton>
                                        </IonCol>
                                        <IonCol>
                                            <IonButton
                                                routerLink='/forgot'
                                                color='primary'
                                                fill='clear'
                                                expand='block'
                                            >
                                                Forgot
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default Register;
